import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { pageContainerStyle, pageContentStyle } from "../utils/styles.js";

@customElement("main-page")
export class MainPage extends LitElement {
    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }
    render() {
        return html`<div class="${pageContainerStyle}">
            <div class="${pageContentStyle}">
                <h1>These are not the droids you are looking for...</h1>
            </div>
        </div>`;
    }
}
